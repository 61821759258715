import React from 'react';
import OS_Page from "./components/OS_Page";

function App() {
  return (
    <div className="App">
      <OS_Page />
    </div>
  );
}

export default App;