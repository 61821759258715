import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import './OS_Page.css';

const AppleIcon = () => (
  <svg 
    fill="#699b5f" 
    version="1.1" 
    id="Capa_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 570.191 570.192" 
    xmlSpace="preserve" 
    stroke="#699b5f"
    width="24" 
    height="24"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier"> 
      <g> 
        <g> 
          <path d="M45.61,354.74c19.865,11.506,47.356,6.503,68.795,3.247c4.572-0.698,64.685-13.125,64.704-12.996 c0.606,5.079-33.406,21.383-37.47,24.29c-18.357,13.124-36.943,31.041-41.791,53.97 c-11.716,55.523,62.715,124.845,111.782,137.057c46.234,11.506,89.664-16.655,105.335-60.113 c11.083-30.756,14.458-66.689,7.919-98.826c-1.518-7.438-19.321-45.122-16.215-50.97c2.549-4.792,41.249,26.744,43.725,29.042 c20.306,18.828,37.038,42.032,45.866,68.446c4.471,13.372,1.82,50.934,21.236,52.305c32.068,2.261,37.635-16.999,27.84-42.207 c-11.423-29.357-26.8-57.316-46.925-81.635c-4.683-5.667-56.95-53.593-56.877-53.703c2.556-3.411,34.673,7.062,38.323,7.754 c23.676,4.489,47.825,7.084,71.932,6.98c45.16-0.193,109.355-10.68,115.506-65.898c5.621-50.346-15.486-121.598-54.694-154.521 c-28.188-23.682-53.186-17.678-79.471,5.187c-13.023,11.331-25.285,23.565-36.543,36.659 c-3.127,3.632-22.476,33.975-26.965,32.549c-2.5-0.792,19.664-40.037,20.398-41.506c10.492-21.071,21.888-45.563,20.511-69.697 c-2.778-48.765-51.319-69.062-93.951-72.271c-50.511-3.791-129.866,10.19-150.071,65.457 c-9.648,26.396,7.255,56.895,17.283,80.778c1.793,4.269,26.332,60.316,25.726,60.371c-9.988,0.912-28.262-34.003-33.045-40.111 c-15.912-20.336-34.701-41.809-58.642-52.736C68.428,98.161,22.92,155.515,9.021,198.3C-6.952,247.517-5.645,325.051,45.61,354.74 z"></path> 
        </g> 
      </g> 
    </g>
  </svg>
);

const MenuBar = () => (
  <div className="menu-bar">
    <span className="apple-icon"><AppleIcon /></span>
    <span>File</span>
    <span>Edit</span>
    <span>View</span>
    <span>Special</span>
  </div>
);

const FolderIcon = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" width="96" height="96">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M0 1H5L8 3H13V5H3.7457L2.03141 11H4.11144L5.2543 7H16L14 14H0V1Z" fill="#000000"></path>
    </g>
  </svg>
);

const TypewriterIcon = () => (
  <svg fill="#000000" version="1.2" baseProfile="tiny" id="H1" xmlns="http://www.w3.org/2000/svg"
    viewBox="-63 65 128 128" xmlSpace="preserve" width="96" height="96">
    <g>
      <rect x="-30.2" y="76.9" width="62.5" height="5.2"/>
      <rect x="-30.2" y="87.3" width="62.5" height="5.2"/>
      <rect x="-30.2" y="97.8" width="62.5" height="5.2"/>
      <path d="M58.3,103c2.9,0,5.2-2.3,5.2-5.2v-5.2c0-2.9-2.3-5.2-5.2-5.2h-5.2c0-5.8-4.7-10.4-10.4-10.4V66.5h-83.4v10.4
        c-5.7,0-10.4,4.7-10.4,10.4h-5.2c-2.9,0-5.2,2.3-5.2,5.2v5.2c0,2.9,2.3,5.2,5.2,5.2h5.2v5.2c-5.8,0-10.4,4.7-10.4,10.4V181
        c0,5.8,4.7,10.4,10.4,10.4H53.1c5.8,0,10.4-4.7,10.4-10.4v-62.4c0-5.8-4.7-10.4-10.4-10.4V103H58.3z M-35.5,71.7h72.9v36.5H19.1
        c-3.6,6.2-10.3,10.5-18.1,10.5s-14.4-4.3-18-10.5h-18.5V71.7z M36.2,130.3c0,2.2-1.7,3.9-3.9,3.9s-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9
        S36.2,128.1,36.2,130.3z M-30.2,134.2c-2.2,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9s3.9,1.7,3.9,3.9S-28,134.2-30.2,134.2z M-26.3,161.5
        c0,2.2-1.7,3.9-3.9,3.9s-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9S-26.3,159.3-26.3,161.5z M-22.4,142c2.2,0,3.9,1.7,3.9,3.9
        c0,2.2-1.7,3.9-3.9,3.9c-2.2,0-3.9-1.7-3.9-3.9C-26.3,143.7-24.6,142-22.4,142z M-14.6,157.6c2.2,0,3.9,1.7,3.9,3.9
        s-1.7,3.9-3.9,3.9c-2.2,0-3.9-1.7-3.9-3.9C-18.5,159.4-16.8,157.6-14.6,157.6z M-14.6,134.2c-2.2,0-3.9-1.7-3.9-3.9
        s1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9S-12.4,134.2-14.6,134.2z M-6.8,142c2.2,0,3.9,1.7,3.9,3.9c0,2.2-1.7,3.9-3.9,3.9
        s-3.9-1.7-3.9-3.9C-10.7,143.7-9,142-6.8,142z M1,157.6c2.2,0,3.9,1.7,3.9,3.9s-1.7,3.9-3.9,3.9s-3.9-1.7-3.9-3.9
        C-2.9,159.4-1.1,157.6,1,157.6z M1,134.2c-2.1,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9s3.9,1.7,3.9,3.9S3.2,134.2,1,134.2z M8.8,142
        c2.2,0,3.9,1.7,3.9,3.9c0,2.2-1.7,3.9-3.9,3.9s-3.9-1.7-3.9-3.9C4.9,143.7,6.6,142,8.8,142z M16.6,157.6c2.2,0,3.9,1.7,3.9,3.9
        s-1.7,3.9-3.9,3.9c-2.2,0-3.9-1.7-3.9-3.9C12.7,159.4,14.5,157.6,16.6,157.6z M16.6,134.2c-2.2,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9
        c2.2,0,3.9,1.7,3.9,3.9S18.8,134.2,16.6,134.2z M24.4,142c2.2,0,3.9,1.7,3.9,3.9c0,2.2-1.7,3.9-3.9,3.9s-3.9-1.7-3.9-3.9
        C20.5,143.7,22.2,142,24.4,142z M-45.9,134.2c-2.2,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9
        S-43.7,134.2-45.9,134.2z M-38.1,149.8c-2.2,0-3.9-1.7-3.9-3.9c0-2.2,1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9
        C-34.2,148.1-35.9,149.8-38.1,149.8z M32.3,181.1h-62.5c-2.2,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9h62.5c2.2,0,3.9,1.7,3.9,3.9
        C36.2,179.3,34.4,181.1,32.3,181.1z M32.3,165.5c-2.2,0-3.9-1.7-3.9-3.9c0-2.2,1.7-3.9,3.9-3.9s3.9,1.7,3.9,3.9
        C36.2,163.7,34.4,165.5,32.3,165.5z M40.1,149.8c-2.2,0-3.9-1.7-3.9-3.9c0-2.2,1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9
        C44,148.1,42.2,149.8,40.1,149.8z M47.9,134.2c-2.2,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9s3.9,1.7,3.9,3.9S50,134.2,47.9,134.2z"/>
    </g>
  </svg>
);

const PaperIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="96" height="96">
    <path fill="#FFFFFF" d="M40 45H8V3h22l10 10z"/>
    <path fill="#90A4AE" d="M38.5 14H29V4.5z"/>
    <path fill="#CFD8DC" d="M16 21h17v2H16zm0 4h13v2H16zm0 4h17v2H16zm0 4h13v2H16z"/>
  </svg>
);

const TrashIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="96" height="96">
    <path fill="#000000" d="M24 21v20h-8V21h8M32 21v20h-6V21h6M34 18H14v24c0 1.104.896 2 2 2h16c1.104 0 2-.896 2-2V18z"/>
    <path fill="#000000" d="M12 16v2h24v-2c0-1.104-.896-2-2-2h-6v-2c0-1.104-.896-2-2-2h-4c-1.104 0-2 .896-2 2v2h-6c-1.104 0-2 .896-2 2z"/>
  </svg>
);

const DesktopIcon = ({ label, icon, onDoubleClick, onDragStart, onDragEnd, position }) => {
  const handleInteraction = (e) => {
    if (e.type === 'touchend') {
      e.preventDefault();
      onDoubleClick();
    }
  };

  return (
    <Draggable bounds="parent" onStart={onDragStart} onStop={onDragEnd} defaultPosition={position}>
      <div 
        className="desktop-icon" 
        onDoubleClick={onDoubleClick}
        onTouchEnd={handleInteraction}
      >
        <div className="icon">
          {icon}
        </div>
        <div className="label">{label}</div>
      </div>
    </Draggable>
  );
};
const Window = ({ title, onClose, children, position }) => (
  <Draggable handle=".window-header" bounds="parent" defaultPosition={position}>
    <div className="window">
      <div className="window-header">
        <div className="close-button" onClick={onClose}></div>
        <span>{title}</span>
      </div>
      <div className="window-content">
        {children}
      </div>
    </div>
  </Draggable>
);

const TextEditor = ({ onSave, onClose, position }) => {
  const [text, setText] = useState('');
  const [fontSize, setFontSize] = useState('16px');
  const [fontFamily, setFontFamily] = useState('Arial');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const handleSave = () => {
    const fileName = prompt('Enter a name for your note:');
    if (fileName) {
      onSave(fileName, text);
    }
  };

  return (
    <Window title="Notes" onClose={onClose} position={position}>
      <div className="text-editor">
        <div className="toolbar">
          <select value={fontSize} onChange={(e) => setFontSize(e.target.value)}>
            <option value="12px">12</option>
            <option value="16px">16</option>
            <option value="20px">20</option>
          </select>
          <select value={fontFamily} onChange={(e) => setFontFamily(e.target.value)}>
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier">Courier</option>
          </select>
          <button onClick={() => setIsBold(!isBold)}>B</button>
          <button onClick={() => setIsItalic(!isItalic)}>I</button>
          <button onClick={handleSave}>Save</button>
        </div>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{
            fontSize,
            fontFamily,
            fontWeight: isBold ? 'bold' : 'normal',
            fontStyle: isItalic ? 'italic' : 'normal',
          }}
        />
      </div>
    </Window>
  );
};

const OS_Page = () => {
  const [openWindows, setOpenWindows] = useState({});
  const [notes, setNotes] = useState([]);
  const [draggingNote, setDraggingNote] = useState(null);
  const [trashPosition, setTrashPosition] = useState({ x: window.innerWidth - 250, y: window.innerHeight - 300 });
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      setTrashPosition({ x: window.innerWidth - 250, y: window.innerHeight - 300 });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sections = [
    { id: 'about', label: 'About Me', icon: <FolderIcon /> },
    { id: 'projects', label: 'Projects', icon: <FolderIcon /> },
    { id: 'skills', label: 'Skills', icon: <FolderIcon /> },
    { id: 'contact', label: 'Contact', icon: <FolderIcon /> },
    { id: 'text-editor', label: 'Notes', icon: <TypewriterIcon /> },
  ];

  const handleSaveNote = (fileName, content) => {
    const newNote = { id: Date.now(), label: fileName, content };
    setNotes([...notes, newNote]);
    setOpenWindows(prev => ({ ...prev, 'text-editor': false }));
  };

  const handleOpenWindow = (id) => {
    const position = {
      x: (windowSize.width - Math.min(windowSize.width * 0.9, 800)) / 2,
      y: (windowSize.height - Math.min(windowSize.height * 0.8, 600)) / 2
    };
    setOpenWindows(prev => ({ ...prev, [id]: position }));
  };

  const handleCloseWindow = (id) => {
    setOpenWindows(prev => ({ ...prev, [id]: false }));
  };

  const handleNoteDragStart = (noteId) => {
    setDraggingNote(noteId);
  };

  const handleNoteDragEnd = (e, data) => {
    const trashRect = {
      left: trashPosition.x,
      right: trashPosition.x + 160,
      top: trashPosition.y,
      bottom: trashPosition.y + 160,
    };

    if (
      e.clientX >= trashRect.left &&
      e.clientX <= trashRect.right &&
      e.clientY >= trashRect.top &&
      e.clientY <= trashRect.bottom
    ) {
      setNotes(notes.filter(note => note.id !== draggingNote));
    }
    setDraggingNote(null);
  };

  const handleTrashDragStop = (e, data) => {
    setTrashPosition({ x: data.x, y: data.y });
  };

  return (
    <div className="classic-mac">
      <MenuBar />
      <div className="desktop">
        {sections.map((section) => (
          <DesktopIcon
            key={section.id}
            label={section.label}
            icon={section.icon}
            onDoubleClick={() => handleOpenWindow(section.id)}
          />
        ))}
        {notes.map((note) => (
          <DesktopIcon
            key={note.id}
            label={note.label}
            icon={<PaperIcon />}
            onDoubleClick={() => handleOpenWindow(`note-${note.id}`)}
            onDragStart={() => handleNoteDragStart(note.id)}
            onDragEnd={handleNoteDragEnd}
          />
        ))}
        <DesktopIcon
          label="Trash"
          icon={<TrashIcon />}
          position={trashPosition}
          onDragEnd={handleTrashDragStop}
        />
        {openWindows['text-editor'] && (
          <TextEditor 
            onSave={handleSaveNote} 
            onClose={() => handleCloseWindow('text-editor')}
            position={openWindows['text-editor']}
          />
        )}
        {Object.entries(openWindows).map(([id, position]) => {
          if (id === 'text-editor' || !position) return null;
          if (id.startsWith('note-')) {
            const note = notes.find(n => `note-${n.id}` === id);
            return (
              <Window
                key={id}
                title={note.label}
                onClose={() => handleCloseWindow(id)}
                position={position}
              >
                <pre>{note.content}</pre>
              </Window>
            );
          }
          return (
            <Window
              key={id}
              title={sections.find(s => s.id === id).label}
              onClose={() => handleCloseWindow(id)}
              position={position}
            >
              Content for {sections.find(s => s.id === id).label}
            </Window>
          );
        })}
      </div>
    </div>
  );
};

export default OS_Page;